import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import AuthedLayout from './layout/AuthedLayout';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage.page';
import LoginPage from './pages/auth/LoginPage.page';

import { Scope } from '@alamere/core';
import { t } from 'i18next';
import { Flag } from '../../../core/src/lib/flags';
import CheckAccess from './components/CheckAccess';
import Title from './components/helmet/Title';
import HqLayout from './hq/layout/HqLayout';
import { HqWorkspacesPage } from './hq/pages/workspaces/HqWorkspacesPage.page';
import UnauthedLayout from './layout/UnauthedLayout';
import JoinPage from './pages/auth/JoinPage.page';
import NoAccessPage from './pages/auth/NoAccessPage.page';
import ResetPasswordPage from './pages/auth/ResetPasswordPage.page';
import SignUpPage from './pages/auth/SignUpPage/SignupPage.page';
import VerifyEmailPage from './pages/auth/VerifyEmailPage.page';
import BonusStructuresPage from './pages/BonusStructuresPage/BonusStructuresPage.page';
import EquityBandsPage from './pages/EquityPage/EquityBandsPage/EquityBandsPage.page';
import EquityContainerPage from './pages/EquityPage/EquityContainerPage.page';
import EquityGlobalMappingsPage from './pages/EquityPage/EquityGlobalMappingsPage/EquityGlobalMappings.page';
import HomePage from './pages/HomePage/HomePage.page';
import JobArchitecturePage from './pages/JobArchitecturePage/JobArchitecturePage.page';
import JobDefinitionsPage from './pages/JobArchitecturePage/JobDefinitionsPage/JobDefinitionsPage.page';
import JobFamiliesPage from './pages/JobFamiliesPage/JobFamiliesPage.page';
import { LoadingPage } from './pages/LoadingPage.page';
import LocationCalculatorPage from './pages/LocationCalculatorPage/LocationCalculatorPage.page';
import { MULTI_ZIP_LOOKUP_PAGE_SLUG, MultiZipLookupPage } from './pages/MultiZipLookupPage/MultiZipLookupPage.page';
import NotFoundPage from './pages/NotFound404Page.page';
import { RangeWidthsPage } from './pages/RangeWidthsPage/RangeWidthsPage.page';
import { WorkspacePermissions } from './pages/SettingsPage/WorkspacePermissions';
import WorkspaceSettingsGeneral from './pages/SettingsPage/WorkspaceSettingsGeneral';
import WorkspaceSettingsPage from './pages/SettingsPage/WorkspaceSettingsPage.page';
import WorkspaceSettingsUser from './pages/SettingsPage/WorkspaceSettingsUsers';
import { AuthProvider } from './providers/auth';
import LocationCalculatorContainerPage from './pages/LocationCalculatorPage/LocationCalculatorContainerPage';
import { CompBandsPage } from './pages/CompBandsPage/CompBandsPage.page';
import SignUp from './pages/auth/SignUpPage/SignUp';
import CompRangesPage from './pages/CompRangesPage/CompRangesPage';
import { CashContainerPage } from './pages/CashContainerPage/CashContainerPage.page';


export default createBrowserRouter([
  {
    element: (
      <AuthProvider>
        <AuthedLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: '/*',
        element: <LoadingPage />,
      },
      {
        path: '/w/:workspaceId/',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to="location-lookup" />,
          },
          {
            path: '*',
            element: <Navigate to="location-lookup" />,
          },
          {
            path: 'no-access',
            element: <NoAccessPage />,
          },
          {
            path: 'location-lookup',
            element: (
              <CheckAccess requiredScopes={[Scope.VIEW_LOCATION_CALCULATOR]}>
                <Title value={t('locationLookupPage.htmlTitle')}>
                  <LocationCalculatorContainerPage />
                </Title>
              </CheckAccess>
            ),
            children: [
              {
                path: '',
                element: <Navigate to="single" />,
              },
              {
                path: '*',
                element: <Navigate to="single" />,
              },
              {
                path: 'single',
                element: (
                  <Title value={t('locationLookupPage.htmlTitle')}>
                    <LocationCalculatorPage />
                  </Title>
                ),
              },
              {
                path: 'multiple',
                element: (
                  <Title value={t('multiZipLookupPage.htmlTitle')}>
                    <MultiZipLookupPage />
                  </Title>
                ),
              }
            ]
          },
          // {
          //   path: MULTI_ZIP_LOOKUP_PAGE_SLUG,
          //   element: (
          //     <CheckAccess requiredScopes={[Scope.VIEW_LOCATION_CALCULATOR]}>
          //       <Title value={t('multiZipLookupPage.htmlTitle')}>
          //         <MultiZipLookupPage />
          //       </Title>
          //     </CheckAccess>
          //   ),
          // },
          {
            path: 'comp-ranges',
            element: (
              <CheckAccess
                requiredScopes={[Scope.EQUITY_BANDS_VIEW]}
                requiredFlags={[Flag.JOB_CATALOG]}
              >
                <Title value={t('compRangesPage.htmlTitle')}>
                  <CompRangesPage />
                </Title>
              </CheckAccess>
            ),
          },
          {
            path: 'equity',
            element: (
              <CheckAccess
                requiredScopes={[Scope.EQUITY_BANDS_VIEW]}
                requiredFlags={[Flag.JOB_CATALOG]}
              >
                <Title value={t('equityPage.htmlTitle')}>
                  <EquityContainerPage />
                </Title>
              </CheckAccess>
            ),
            children: [
              {
                path: '',
                element: <Navigate to="bands" />,
              },
              {
                path: '*',
                element: <Navigate to="bands" />,
              },
              {
                path: 'bands',
                element: (
                  <Title value={t('equityPage.bands.htmlTitle')}>
                    <EquityBandsPage />
                  </Title>
                ),
              },
              {
                path: 'global-mappings',
                element: (
                  <Title value={t('equityPage.globalMappings.htmlTitle')}>
                    <EquityGlobalMappingsPage />
                  </Title>
                ),
              },
            ],
          },
          {
            path: 'job-architecture',
            element: (
              <CheckAccess
                requiredScopes={[Scope.JOB_ARCHITECTURE_VIEW]}
                requiredFlags={[Flag.JOB_CATALOG]}
              >
                <Title value={t('jobArchitecturePage.htmlTitle')}>
                  <JobDefinitionsPage />
                </Title>
              </CheckAccess>
            ),
          },
          {
            path: 'global-level',
            element: (
              <CheckAccess
                requiredScopes={[Scope.JOB_ARCHITECTURE_VIEW]}
                requiredFlags={[Flag.JOB_CATALOG]}
              >
                <Title value={t('jobArchitecturePage.htmlTitle')}>
                  <JobArchitecturePage />
                </Title>
              </CheckAccess>
            ),
          },
          {
            path: 'bonus-structures',
            element: (
              <CheckAccess
                requiredScopes={[Scope.BONUS_STRUCTURES_VIEW]}
                requiredFlags={[Flag.JOB_CATALOG]}
              >
                <Title value={t('bonusStructuresPage.htmlTitle')}>
                  <BonusStructuresPage />
                </Title>
              </CheckAccess>
            ),
          },
          {
            path: 'job-families',
            element: (
              <CheckAccess
                requiredScopes={[Scope.JOB_FAMILIES_VIEW]}
                requiredFlags={[Flag.JOB_CATALOG]}
              >
                <Title value={t('jobFamiliesPage.htmlTitle')}>
                  <JobFamiliesPage />
                </Title>
              </CheckAccess>
            ),
          },
          {
            path: 'cash',
            element: (
              <CheckAccess
                requiredScopes={[Scope.RANGE_WIDTHS_VIEW, Scope.COMP_BANDS_VIEW]}
                requiredFlags={[Flag.JOB_CATALOG]}
              >
                <Title value={t('global.cash')}>
                  <CashContainerPage />
                </Title>
              </CheckAccess>
            ),
            children: [
              {
                path: '',
                element: <Navigate to="comp-bands" />,
              },
              {
                path: '*',
                element: <Navigate to="comp-bands" />,
              },
              {
                path: 'range-widths',
                element: (
                  <Title value={t('rangeWidthsPage.htmlTitle')}>
                    <RangeWidthsPage />
                  </Title>
                ),
              },
              {
                path: 'comp-bands',
                element: (
                  <Title value={t('compBandsPage.htmlTitle')}>
                    <CompBandsPage />
                  </Title>
                ),
              },
            ]
          },
          {
            path: 'settings',
            element: (
              <CheckAccess requiredScopes={[Scope.EDIT_WORKSPACE]}>
                <WorkspaceSettingsPage />
              </CheckAccess>
            ),
            children: [
              {
                path: '',
                element: <Navigate to="general" />,
              },
              {
                path: '*',
                element: <Navigate to="general" />,
              },
              {
                path: 'general',
                element: (
                  <Title value={t('workspaceSettingsPage.htmlTitle')}>
                    <WorkspaceSettingsGeneral />
                  </Title>
                ),
              },
              {
                path: 'users',
                element: (
                  <Title value={t('workspaceSettingsPage.htmlTitle')}>
                    <WorkspaceSettingsUser />
                  </Title>
                ),
              },
              {
                path: 'permissions',
                element: (
                  <Title value={t('workspacePermissionsPage.htmlTitle')}>
                    <WorkspacePermissions />
                  </Title>
                ),
              },
            ],
          },
        ],
      },

      {
        path: '/404',
        element: (
          <Title value={t('notFoundPage.htmlTitle')}>
            <NotFoundPage />
          </Title>
        ),
      },
    ],
  },
  {
    element: (
      <AuthProvider>
        <Title value={t('hqPage.htmlTitle')}>
          <HqLayout />
        </Title>
      </AuthProvider>
    ),
    children: [
      {
        path: '/hq',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <Navigate to="w" />,
          },
          {
            path: '*',
            element: <Navigate to="w" />,
          },
          {
            path: 'w',
            element: <HqWorkspacesPage />,
          },
          { path: 'w/:workspaceId', element: <Navigate to="/hq/w" /> },
        ],
      },
    ],
  },
  {
    element: (
      <AuthProvider>
        <UnauthedLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: 'login',
        element: (
          <Title value={t('loginPage.htmlTitle')}>
            <LoginPage />
          </Title>
        ),
      },
      {
        path: 'signup',
        element: (
          <Title value={t('signUpPage.htmlTitle')}>
            {/*<SignUpPage />*/}
            <SignUp />
          </Title>
        ),
      },
      {
        path: 'login/forgot-password',
        element: (
          <Title value={t('forgotPasswordPage.htmlTitle')}>
            <ForgotPasswordPage />
          </Title>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <Title value={t('resetPasswordPage.htmlTitle')}>
            <ResetPasswordPage />
          </Title>
        ),
      },
      {
        path: 'join',
        element: (
          <Title value={t('joinPage.htmlTitle')}>
            <JoinPage />
          </Title>
        ),
      },
      {
        path: 'verify-email',
        element: (
          <Title value={t('verifyEmailPage.htmlTitle')}>
            <VerifyEmailPage />
          </Title>
        ),
      },
    ],
  },
]);
